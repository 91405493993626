'use client'

import { IconArrowRight } from '@tabler/icons-react'
import Image from 'next/image'
import Link from 'next/link'

export default function NotFound() {
	return (
		<div className='w-screen h-screen flex flex-col items-center justify-center gap-10'>
			<Image
				src='/logo.svg'
				alt='404'
				width={200}
				height={200}
				className='rounded-xl'
			/>
			<h1 className='text-4xl'>404 - Not Found</h1>
			<Link
				href='/'
				className='flex gap-2 items-center text-blue-500 hover:underline cursor-pointer'
			>
				Back to homepage{' '}
				<IconArrowRight
					size={16}
					className='rtl:-scale-x-100'
				/>
			</Link>
		</div>
	)
}
